import React, { useState } from 'react';
import { INostriPartnerBanner } from '@/components/home/i-nostri-partner';
import { Hero } from '@/components/layout/header/hero';
import { Layout } from '@/components/layout';
import { AccediAiServizi } from '@/components/home/accedi-ai-servizi';
import { ScopriCome } from '@/components/home/scopri-come';
import { CreaAbbonamento } from '@/components/home/crea-abbonamento';
import { Divider } from '@/components/ui/Divider';
import { HomePopup } from '@/components/popups/HomePopup';
import { Banner } from '@/components/banner';
import { useBanner } from '@/hooks/useBanner';
import { usePopup } from '@/hooks/usePopup';
import { getCurrentDateTime } from '@/constants/today';

const IndexPage = () => {
  const [isPopupOpen, setPopupOpen] = useState(true);
  const components = useBanner('home');
  const today = getCurrentDateTime();
  const popup = usePopup(today);
  return (
    <Layout>
      {popup && isPopupOpen && (
        <HomePopup
          content={popup}
          isPopupOpen={isPopupOpen}
          setPopupOpen={setPopupOpen}
        />
      )}
      <Hero isHome={true} />
      <Banner components={components} />
      <AccediAiServizi />
      <ScopriCome />
      <Divider size={5} />
      <CreaAbbonamento isOpen={false} />
      <INostriPartnerBanner />
      <Divider size={5} />
    </Layout>
  );
};

export default IndexPage;

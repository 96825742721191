import { isWithinInterval, parse } from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';

export const usePopup = (today: string) => {
  const isDateWithinRange = (
    date: string,
    startDate: string,
    endDate: string,
  ) => {
    const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
    const parsedStartDate = parse(startDate, 'yyyy-MM-dd HH:mm:ss', new Date());
    const parsedEndDate = parse(endDate, 'yyyy-MM-dd HH:mm:ss', new Date());
    const withinRange = isWithinInterval(parsedDate, {
      start: parsedStartDate,
      end: parsedEndDate,
    });
    return withinRange;
  };

  const { allWpPopup } = useStaticQuery(graphql`
    query {
      allWpPopup(filter: { popups: { abilitato: { eq: true } } }) {
        nodes {
          title
          popups {
            abilitato
            dal
            al
            immagine {
              mediaItemUrl
            }
          }
        }
      }
    }
  `);
  const match = allWpPopup.nodes.find((popup: any) =>
    isDateWithinRange(today, popup.popups.dal, popup.popups.al),
  );
  if (!match) return false;
  return match;
};

import React, { FC } from 'react';
import { Button } from '../ui/Button';
import { IComponent } from '@/types';

interface BannerProps {
  components: IComponent[];
}

export const Banner: FC<BannerProps> = ({ components }) => {
  const ActionButton = ({ title, url }: IComponent['button']) => (
    <a href={url} target="_blank">
      <Button
        style={{ maxWidth: '250px' }}
        className="bg-primary text-white p-1 pe-5 ps-5"
      >
        <p className="text-uppercase mb-0">{title}</p>
      </Button>
    </a>
  );

  const DesktopView = () => (
    <div className="d-none d-md-flex row justify-content-center">
      {components?.map((component: IComponent, index: number) => {
        if (component.isvisible) {
          switch (component.type) {
            case 'button':
              return (
                <div key={index} className="col-auto align-self-center">
                  <ActionButton
                    title={component.button.title}
                    url={component.button.url}
                  />
                </div>
              );
            case 'logo':
              return (
                <div key={index} className="col-auto align-self-center">
                  <a
                    href={component.logo.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      height={70}
                      src={component.logo.image.mediaItemUrl}
                      alt={component.logo.title}
                    />
                  </a>
                </div>
              );
            case 'pdf':
              return (
                <div key={index} className="col-auto align-self-center">
                  <a
                    href={component.pdf.file.mediaItemUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {component.pdf.title}
                  </a>
                </div>
              );
            default:
              return null;
          }
        }
      })}
    </div>
  );

  const MobileView = () => (
    <>
      {components?.map((component: IComponent, index: number) => {
        if (component.isvisible) {
          switch (component.type) {
            case 'button':
              return (
                <div
                  key={index}
                  className="d-flex d-md-none justify-content-center mt-5"
                >
                  <ActionButton
                    title={component.button.title}
                    url={component.button.url}
                  />
                </div>
              );
            case 'logo':
              return (
                <div
                  key={index}
                  className="d-flex d-md-none justify-content-center mt-5"
                >
                  <a
                    href={component.logo.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      height={70}
                      src={component.logo.image.mediaItemUrl}
                      alt={component.logo.title}
                    />
                  </a>
                </div>
              );
            case 'pdf':
              return (
                <div
                  key={index}
                  className="d-flex d-md-none justify-content-center mt-5"
                >
                  <a
                    href={component.pdf.file.mediaItemUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {component.pdf.title}
                  </a>
                </div>
              );

            default:
              return null;
          }
        }
      })}
    </>
  );

  return (
    <div className="pt-5 pb-5">
      <DesktopView />
      <MobileView />
    </div>
  );
};

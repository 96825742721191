import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

export const INostriPartnerBanner: FC = () => {
  const title = 'I Nostri Partner';
  const flowbirdImg = './images/logo-flowbird.png';
  const easyparkImg = './images/logo-easypark.png';
  const bravImg = './images/logo-brav.png';
  const telepassImg = './images/logo-telepass.png';
  const skidataImg = './images/logo-skidata.png';

  const MobileView = () => (
    <div className="d-block d-lg-none">
      <h1 className="text-center text-primary text-uppercase font-rubik h2">
        {title}
      </h1>
      <div className="row">
        <div className="col-9 mx-auto">
          <div className="row">
            <div className="col-4 mb-3">
              <a href="https://flowbird.it/" target="_blank">
                <StaticImage
                  width={96}
                  quality={100}
                  src={flowbirdImg}
                  alt="flowbird"
                />
              </a>
            </div>
            <div className="col-4 mb-3">
              <a href="https://easyparkitalia.it/it" target="_blank">
                <StaticImage
                  width={96}
                  quality={100}
                  src={easyparkImg}
                  alt="easypark"
                />
              </a>
            </div>
            <div className="col-4 mb-3">
              <a href="https://www.brav.it/" target="_blank">
                <StaticImage
                  width={96}
                  quality={100}
                  src={bravImg}
                  alt="brav-logo"
                />
              </a>
            </div>
            <div className="col-4 mb-3">
              <a href="https://www.telepass.it/" target="_blank">
                <StaticImage
                  width={96}
                  quality={100}
                  src={telepassImg}
                  alt="telepass-logo"
                />
              </a>
            </div>
            <div className="col-4">
              <a href="https://www.skidata.com/en/" target="_blank">
                <StaticImage
                  width={96}
                  quality={100}
                  src={skidataImg}
                  alt="skidata-logo"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const DesktopView = () => (
    <div className="d-none d-lg-block">
      <div className="row">
        <div className="col-6 align-self-center">
          <h1 className="text-end text-primary text-uppercase font-rubik h2 me-5">
            {title}
          </h1>
        </div>
        <div className="col-6 align-self-center">
          <div className="row">
            <div className="col-2 my-auto">
              <a href="https://flowbird.it/" target="_blank">
                <StaticImage
                  width={96}
                  quality={100}
                  src={flowbirdImg}
                  alt="flowbird"
                />
              </a>
            </div>
            <div className="col-2">
              <a href="https://easyparkitalia.it/it" target="_blank">
                <StaticImage
                  width={96}
                  quality={100}
                  src={easyparkImg}
                  alt="easypark"
                />
              </a>
            </div>
            <div className="col-2">
              <a href="https://www.brav.it/" target="_blank">
                <StaticImage
                  width={96}
                  quality={100}
                  src={bravImg}
                  alt="brav-logo"
                />
              </a>
            </div>
            <div className="col-2">
              <a href="https://www.telepass.com/" target="_blank">
                <StaticImage
                  width={96}
                  quality={100}
                  src={telepassImg}
                  alt="telepass-logo"
                />
              </a>
            </div>
            <div className="col-2">
              <a href="https://www.skidata.com/en/" target="_blank">
                <StaticImage
                  width={96}
                  quality={100}
                  src={skidataImg}
                  alt="skidata-logo"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-100 bg-secondary mt-5 mb-5">
      <div className="container p-5">
        <MobileView />
        <DesktopView />
      </div>
    </div>
  );
};

import { IComponent } from '@/types';
import { graphql, useStaticQuery } from 'gatsby';

export const useBanner = (slug: string) => {
  const { allWpBanner } = useStaticQuery(graphql`
    query {
      allWpBanner {
        nodes {
          slug
          banners {
            component {
              isvisible
              type
              button {
                title
                url
              }
              logo {
                title
                url
                image {
                  mediaItemUrl
                }
              }
              pdf {
                title
                file {
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    }
  `);
  const match = allWpBanner.nodes.find(
    (banner: IComponent) => banner.slug === slug,
  );
  const components = match.banners.component;
  return components as IComponent[];
};

import React, { Dispatch, SetStateAction, FC } from 'react';
import { IoMdClose } from 'react-icons/io';
import Modal from 'react-bootstrap/esm/Modal';

interface HomePopupProps {
  isPopupOpen: boolean;
  setPopupOpen: Dispatch<SetStateAction<boolean>>;
  content?: any;
}
export const HomePopup: FC<HomePopupProps> = ({
  isPopupOpen,
  setPopupOpen,
  content,
}) => {
  const onClose = () => {
    setPopupOpen(false);
  };
  return (
    <Modal
      show={isPopupOpen}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Header>
        <div style={{ height: '36px' }} className="position-relative w-100">
          <div className="position-absolute end-0 top-0">
            <IoMdClose
              color="#0b577b"
              size={36}
              className="cursor-pointer"
              onClick={onClose}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ minHeight: '420px' }}
          className="bg-secondary d-flex justify-content-center align-items-center"
        >
          <img
            className="img-fluid"
            src={content?.popups?.immagine?.mediaItemUrl}
            alt={content?.title}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Button } from '../ui/Button';

export const ScopriCome = () => {
  const Card = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    &:hover h2 {
      text-decoration: underline !important;
    }
  `;
  return (
    <div
      style={{ maxWidth: '1024px' }}
      className="text-center container position-relative"
    >
      <h1 className="text-primary font-rubik h4">Accedi per scoprire come</h1>
      <div className="row mb-5 mt-5">
        <div className="col-12 col-lg-4">
          <StaticImage
            className="d-none d-lg-block position-absolute"
            style={{ top: '260px', left: '-140px' }}
            src="./images/uomo.png"
            height={400}
            alt="uomo"
          />
          <Card to="/paga-la-sosta">
            <div className="card bg-secondary p-4">
              <div
                style={{
                  width: '120px',
                  margin: '0 auto',
                  paddingBottom: '20px',
                }}
              >
                <StaticImage
                  src="./images/1.png"
                  quality={100}
                  formats={['auto', 'webp', 'avif']}
                  alt="gps"
                />
              </div>
              <h2 className="text-primary text-uppercase mb-0 font-rubik h5">
                Paga la sosta
              </h2>
              <p className="text-white font-rubik">
                Scarica le nostre app per pagare la sosta direttamente dal tuo
                smart phone
              </p>
            </div>
          </Card>
        </div>
        <div className="col-12 col-lg-4 mt-lg-5 mt-2 mb-2">
          <Card to="/abbonamenti-e-pass">
            <div className="card bg-secondary p-4">
              <div
                style={{
                  width: '120px',
                  margin: '0 auto',
                  paddingBottom: '20px',
                }}
              >
                <StaticImage
                  src="./images/2.png"
                  quality={100}
                  formats={['auto', 'webp', 'avif']}
                  alt="abbonamenti e pass"
                />
              </div>
              <h2 className="text-primary text-uppercase mb-0 font-rubik h5">
                Abbonamenti e pass
              </h2>
              <p className="text-white font-rubik">
                Accedi a questa sessione per attivare il tuo abbonamento oppure
                per richiedere un pass
              </p>
            </div>
          </Card>
        </div>
        <div className="col-12 col-lg-4">
          <StaticImage
            src="./images/donna.png"
            className="d-none d-lg-block position-absolute"
            style={{ top: '260px', right: '-140px' }}
            height={400}
            alt="donna"
          />
          <Card to="/paga-la-penale">
            <div className="card bg-secondary p-4">
              <div
                style={{
                  width: '120px',
                  margin: '0 auto',
                  paddingBottom: '20px',
                }}
              >
                <StaticImage
                  src="./images/3.png"
                  quality={100}
                  formats={['auto', 'webp', 'avif']}
                  alt="paga la penale"
                />
              </div>
              <h2 className="text-primary text-uppercase mb-0 font-rubik h5">
                Paga la penale
              </h2>
              <p className="text-white font-rubik mb-0">
                Hai ricevuto una contravvenzione? Accedi al nostro sistema per
                pagare la tua penale
              </p>
            </div>
          </Card>
        </div>
      </div>
      <div style={{ marginTop: '150px' }} className="mb-5">
        <h1 className="text-primary font-rubik h4">
          Hai qualche dubbio? consulta le nostre
        </h1>
        <Link to="/faq">
          <Button className="bg-secondary text-primary p-2 mt-3 h3 font-rubik">
            FAQ
          </Button>
        </Link>
      </div>
    </div>
  );
};

import React from 'react';

export const AccediAiServizi = () => (
  <div className="mt-5 mb-5">
    <div className="bg-secondary text-center text-white">
      <div className="p-4">
        <h1 style={{ fontSize: '1.6rem' }} className="mx-auto font-rubik">
          Accedi ai nostri servizi
        </h1>
        <p style={{ fontFamily: 'Oxygen', fontSize: '1.1rem' }}>
          La sessione dei servizi Vicenza Parcheggi ti aiuta a facilitare le
          operazioni legate alla sosta della tua città
        </p>
      </div>
    </div>
  </div>
);
